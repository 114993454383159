import React from "react"
import {
  useI18next,
} from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PublicationsContent from "../components/publications-content"


const PublicationsPage = () => {
  const {t} = useI18next();
  return (
    <Layout>
      <SEO title={t("MENU.PUBLICATIONS")} />
      <PublicationsContent/>
    </Layout>
  )
}

export default PublicationsPage