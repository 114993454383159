import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
  I18nextContext
} from "gatsby-plugin-react-i18next"

import styled from "styled-components"

import { ContainerWithBackground, Content, ContentInner} from '../styles/global-styles';
import SectionHeader from './section-header';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import backgroundImage from '../images/background-books.jpg'

// const Container = styled.div`
//     width: 100%;
//     // padding-top: 60px;
//     text-align: center;
//     // background: #ACD433;
//     // background: #2c466b;
//     // color: wheat;
//     // margin-top: 15px;
//     margin: 0 auto;
    
        
//     @media screen and (max-width: 1220px) {
//         margin-top: 0;
//         padding-top: 0;
//     }
// `

const Container = styled(ContainerWithBackground)`
    @media screen and (max-width: 1220px) {
        margin-top: 0;
        padding-top: 0;
    }
`

const Section = styled.div`
    
`


const ContentWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    background-color: ${props=>props.$backgroundColour};
    color: ${props=>props.$colour};
    padding-top: 60px;
    // padding-bottom: 60px;
    
    @media screen and (max-width: 1220px) {
        // padding-top: 90px;
        // margin-top: 75px;
    }

    @media screen and (max-width: 1100px) {
        // padding-top: 90px;
        margin-top: 75px;
    }
    
        
    @media screen and (max-width: 767px) {
        padding-top: 73px;
    }
`

// const ContentInner = styled.div`
//     width: 73.306%;
//     margin: 0 auto;
// `

// const Content = styled.div`
//     margin: 0 auto;
//     margin-top: 40px;
//     max-width: 90%;
    
//     p { 
        
//         font-size: 20px;
//         line-height: 1.4;
//         margin-top: 14px;
//         font-weight: 400;
//         white-space: pre-wrap;
//         margin-bottom: 14px;
//         text-align: left;
//     }
// `

const AvatarsContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
`

const AvatarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    margin: 25px;
`

const ArticlesWrapper = styled.div`
    margin-top: 16px;
`

const StyledAvatar = styled(Avatar)`
    width: 160px;
    height: 160px;
    margin-bottom: 20px;
`

const Name = styled.h4`
    font-size: 20px;
    text-transform: uppercase;
`

const Titles = styled.p`
    font-size: 16px;
    text-align: center;
`

const ArticlesContent = styled(Content)`
    margin-top: 56px;
    background: none;
`

const StyledButton = styled(Button)`
    margin: 8px;
    width: 500px;
    display: block;
    font-size: 20px;
    
    @media screen and (max-width: 1220px) {
        width: auto;
        font-size: 16px;
    }
`


const PublicationsContent = () => {
    const {t} = useI18next();
  
    return (
        <Container backgroundImage={backgroundImage}>
            <ContentWrapper >
                <SectionHeader title={t("PUBLICATIONS.PUBLICATIONS")} />
                <ArticlesContent>
                    <ContentInner>
                        <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                            <Link to="/publications/articles-white-papers"><StyledButton variant="contained" color="primary">{t("PUBLICATIONS.ARTICLES-WHITE-PAPERS")}</StyledButton></Link>
                            <Link to="/publications/presentations"><StyledButton variant="contained" color="primary">{t("PUBLICATIONS.PRESENTATIONS")}</StyledButton></Link>
                            <Link to="/publications/books"><StyledButton variant="contained" color="primary">{t("PUBLICATIONS.BOOKS")}</StyledButton></Link>
                        </Box>
                    </ContentInner>
                </ArticlesContent>
            </ContentWrapper>
        </Container>
    )
}

export default PublicationsContent